import React from "react"
import Helmet from "react-helmet"
import "../new/_main.scss"
// components
import Header from "./v2023/Header/header"
import Footer from "./v2023/Footer/footer"
import { withPrefix } from "gatsby-link"
import FloatingButtons from "./v2023/FloatingButtons/floating-buttons"

/*const getBrowserLanguage = () => {

    if (typeof navigator === `undefined`) {
        return "en"
    }

    const lang = navigator && navigator.language && navigator.language.split("-")[0]

    if (!lang) return "en"

    if (!["es", "en"].includes(lang)) {
        return "en"
    }

    return lang
}*/

const NewLayout = ({ children, pageContext }) => {
    // documentReady(() => {
    //     const browserLang = getBrowserLanguage()
    //     let languageChecked = getCookie("language-checked")
    //
    //     if (languageChecked == null || languageChecked === "") {
    //         languageChecked = browserLang
    //         setCookie("language-checked", browserLang, new Date("01-01-2100"))
    //     }
    //
    //     if (typeof window !== "undefined" && pageContext.langKey !== languageChecked) {
    //         setTimeout(() => navigate(pageContext.translates[languageChecked]))
    //     }
    // })

    return (
        <>
            <Helmet>
                <title>Tecalis</title>
                <meta name="description" content="web 2022" />
                <meta name="p:domain_verify" content="803e8c951a2ad570b30570ddfdda48d9" />
                <link rel="stylesheet" href="https://use.typekit.net/acr2trl.css" />
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css"
                      integrity="sha512-NhSC1YmyruXifcj/KFRWoC561YpHpc5Jtzgvbuzx5VozKpWvQ+4nXhPdFgmx8xqexRcpAglTj9sIBWINXa8x5w==" crossorigin="anonymous"
                      referrerpolicy="no-referrer" />
                <link rel="stylesheet" href="https://unpkg.com/aos@next/dist/aos.css" />
                <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js" />
                <script charSet="utf-8" type="text/javascript" src="//js.hsforms.net/forms/v2.js" />
                <script charSet="utf-8" type="text/javascript" src={withPrefix("scripts/hotjar.js")} />
                {/*<script src="https://unpkg.com/aos@next/dist/aos.js"></script>*/}
            </Helmet>
            <Header lang={pageContext.langKey} translates={pageContext.translates} />
            {children}
            <FloatingButtons lang={pageContext.langKey} />
            <Footer lang={pageContext.langKey} translates={pageContext.translates} />
        </>
    )
}


export default NewLayout
